<template>
  <section role="main">
    <form
      class="mt-5 mr-2 ml-2 justify-content-md-center"
      @submit.prevent="submit"
    >
      <div class="col">
        <section class="card">
          <header class="card-header"></header>
          <div class="card-body" id="frmBody">
            <div v-if="!isLoading">
              <div class="row">
                <div class="col-lg-6 mx-auto text-center">
                  <h4 class="label f-weight-550">
                    Please answer these questions about yourself
                  </h4>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-6 mx-auto">
                  <label class="label f-weight-550">
                    Select one condition for immediate family health history
                  </label>
                  <multiselect
                    :searchable="false"
                    v-model="form.condition"
                    :options="conditionSelection"
                    :multiple="true"
                    @select="selectChange"
                    @remove="removeChange"
                  />
                </div>
              </div>

              <div
                class="form-group row"
                v-for="(famCondition, index) in form.familyCondition"
                :key="famCondition.question"
              >
                <div class="col-lg-6 mx-auto">
                  <label class="label f-weight-550 me-5">
                    {{ famCondition.question }}
                  </label>
                  <span
                    style="margin-left:10px;cursor: pointer;"
                    @click="addSameConditionToFam(famCondition)"
                  >
                    <i class="fa-solid fa-person-circle-plus"></i>
                    Add Family
                  </span>
                  <span
                    style="margin-left:10px;cursor: pointer;"
                    @click="removeFamCondition(famCondition, index)"
                  >
                    <i class="fa-solid fa-person-circle-xmark"></i>
                    Remove
                  </span>
                  <div class="row">
                    <div class="col-lg-6">
                      <multiselect
                        :searchable="false"
                        v-model="famCondition.family"
                        :options="familySelection"
                        :multiple="false"
                      />
                    </div>
                    <div class="col-lg-6">
                      <multiselect
                        :searchable="false"
                        v-model="famCondition.stat"
                        :options="statusSelection"
                        :multiple="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="col-12">
                <content-placeholders :rounded="true" :centered="true">
                  <content-placeholders-text :lines="5" />
                </content-placeholders>
              </div>
            </div>
          </div>
          <footer class="card-footer"></footer>
        </section>
        <footer class="card-footer">
          <div class="row justify-content-end">
            <div class="col-sm-9">
              <button
                type="reset"
                class="btn btn-default mr-2"
                @click="prev"
                v-if="getTaskPreviousTaskType"
              >
                Back
              </button>
              <btn-saving :is-saving="isSaving" />
            </div>
          </div>
        </footer>
      </div>
    </form>
  </section>
</template>

<script>
import Multiselect from 'vue-multiselect'
import '../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css'
import api from '../../api/http-common.js'
import { mapGetters, mapActions } from 'vuex'
import BtnSaving from '../button-saving.vue'
export default {
  components: {
    Multiselect,
    BtnSaving
  },
  data () {
    return {
      isLoading: false,
      isSaving: false,
      form: {
        condition: [],
        familyCondition: []
      },
      familyHistoryData: null
    }
  },
  computed: {
    ...mapGetters([
      'getProfilePatientAssociated',
      'getTaskCurrentCaseHistoryId',
      'getTaskPreviousTaskType',
      'getTaskCurrentTaskType',
      'currentPatientAssociated'
    ]),
    conditionSelection () {
      return [
        'Cancer',
        'Stroke',
        'Thyroid problems',
        'Asthma',
        'Heart attack',
        'HIV',
        'Angina/Chest pain',
        'Diabetes',
        'Arthritis',
        'Nothing significant',
        'Unknown'
      ]
    },
    familySelection () {
      return [
        'Father',
        'Mother',
        'Brother',
        'Sister',
        'Grandfather',
        'Grandmother',
        'Son',
        'Daughter'
      ]
    },
    statusSelection () {
      return ['Past', 'Present']
    }
  },
  watch: {
    'form.condition': function (val) {
      //   console.log('val', val)
    }
  },
  methods: {
    ...mapActions(['setTaskCurrentTaskType']),
    addSameConditionToFam (famCondition) {
      this.form.familyCondition.push({
        question: famCondition.question,
        family: [],
        stat: []
      })
    },
    removeFamCondition (famCondition, index) {
      this.form.familyCondition.splice(index, 1)

      const containsQuestion = this.form.familyCondition.find(
        el => el.question === famCondition.question
      )
      if (!containsQuestion) {
        this.form.condition = this.form.condition.filter(
          el => el !== famCondition.question
        )
      }
    },
    selectChange (selected) {
      switch (selected) {
        case 'Nothing significant':
        case 'Unknown':
          setTimeout(() => {
            this.form.condition = [selected]
            this.form.familyCondition = []
          }, 1500)
          break
        default:
          this.form.familyCondition.push({
            question: selected,
            family: [],
            stat: []
          })
          break
      }
    },
    removeChange (remove) {
      if (remove === 'Nothing significant' || remove === 'Unknown') {
        this.form.familyCondition = []
        return
      }
      this.form.familyCondition = this.form.familyCondition.filter(
        x => x.question !== remove
      )
    },
    prev () {
      const currentIndex = this.getTaskPreviousTaskType.findIndex(
        c => c === this.getTaskCurrentTaskType
      )
      let prevPage = ''
      if (currentIndex === -1) {
        prevPage = this.getTaskPreviousTaskType[
          this.getTaskPreviousTaskType.length - 1
        ]
      } else {
        prevPage = this.getTaskPreviousTaskType[currentIndex - 1]
      }
      this.setTaskCurrentTaskType({
        back: true,
        page: prevPage,
        removePage: this.getTaskCurrentTaskType
      })
    },
    async submit () {
      this.isSaving = true
      // alert('payload!')
      const payload = []
      this.form.familyCondition.forEach((el, index) => {
        payload.push({
          facilityId: this.currentPatientAssociated.facilityID,
          caseHistoryId: this.getTaskCurrentCaseHistoryId,
          id: 0,
          description: el.question,
          relation: el.family,
          present: el.stat === 'Present',
          notPresent: false,
          past: el.stat === 'Past',
          unknown: el.question === 'Unknown'
        })
      })

      await api.task.request.insertCaseHistoryFamilyHealthHistory({
        facilityId: this.currentPatientAssociated.facilityID,
        caseHistoryId: this.getTaskCurrentCaseHistoryId,
        histories: payload
      })

      this.isSaving = false
      this.setTaskCurrentTaskType('NphThankyou')
    }
  },
  async activated () {
    this.isLoading = true
    const familyHistoryData = await api.task.request.getCaseHistoryFamilyHealthHistory(
      {
        facilityId: this.currentPatientAssociated.facilityID,
        caseHistoryId: this.getTaskCurrentCaseHistoryId
      }
    )
    this.familyHistoryData = familyHistoryData.data
    this.form.familyCondition = []
    this.form.condition = []

    // Construct data
    this.familyHistoryData.forEach(el => {
      var isConditionAlreadyAdded = this.form.condition.find(
        el2 => el.description
      )
      if (!isConditionAlreadyAdded) {
        this.form.condition.push(el.description)
      }

      let stat = ''
      if (el.past) {
        stat = 'Past'
      }
      if (el.present) {
        stat = 'Present'
      }

      this.form.familyCondition.push({
        family: el.relation,
        question: el.description,
        stat: stat
      })
    })

    this.isLoading = false

    setTimeout(() => {
      const el = document.getElementById('frmBody')
      el.scrollIntoView({ behavior: 'smooth' })
      console.log('should be scrolling to top!')
    }, 1000)
  }
}
</script>

<style></style>
